import { offerActions } from '../ActionTypes';
import { updateState } from '../../middlewares/utills';

const defaultState = {
  response: null,
  loading: false,
  error: null,
  screen: false,
};

// eslint-disable-next-line default-param-last
export default (state = defaultState, actions) => {
  switch (actions.type) {
    case offerActions.FETCH_START:
      return updateState(state, { loading: true, error: null });
    case offerActions.FETCH_SUCCESS:
      return updateState(state, { loading: false, ...actions.payload, });
    case offerActions.FETCH_FAILED:
      return updateState(state, { loading: false, error: actions.payload });

    default: return state;
  }
};