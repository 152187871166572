import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { toast, Slide } from 'react-toastify';
import './css/index.css'

// Reudx
import { Provider } from 'react-redux';
import configureStore from './redux';

import Routes from './routes';
import 'react-toastify/dist/ReactToastify.min.css';

export const store = configureStore();

toast.configure({
  autoClose: 2000,
  hideProgressBar: true,
  pauseOnHover: false,
  transition: Slide,
  toastClassName: 'toast-container',
  bodyClassName: 'toast-body',
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);