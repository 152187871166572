import React from 'react';
import Loadable from 'react-loadable';
import { Switch, Route } from 'react-router-dom';

const Home = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ '../screens/Home'),
  loading: () => null
});

const View = Loadable({
  loader: () => import(/* webpackChunkName: "View" */ '../screens/View'),
  loading: () => null
});

function Routes() {
  return (
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/:id" component={Home} exact />
      <Route path="/view/:id" component={View} exact />
    </Switch >
  );
}

export default Routes;